<template>
    <div class="enabled_countries_root">
        <div class="country_add" style="font-size: 1.5rem;">
            <b-form-group label-for="register-country" class="countryselect">
                <country-select v-model="countryCode" :country="countryCode" required className="form-control" />
            </b-form-group>
            <b-button variant="primary" size="lg" style="height: 3rem; margin-top: -1rem;" @click="selectCountry">Add</b-button>
        </div>
        <div>
            <b-table responsive :fields="tableColumns" primary-key="id" show-empty
                empty-text="No matching records found"
                :items="enabledCountries">
                <template #cell(removecountry)="data">
                    <i class="fa-solid fa-trash" style="color: rgb(205, 40, 40); margin-left: 1rem; cursor: pointer;" @click="removeCountry(data.item.id)"></i>
                </template>
            </b-table>
        </div>
    </div>
</template>

<script>
import { BTable, BFormGroup, BButton } from 'bootstrap-vue';
import { mapState, mapActions, mapMutations } from 'vuex';
import vueCountryRegionSelect from 'vue-country-region-select';
import isoCountries from 'i18n-iso-countries';

export default {
    components: {
        BTable,
        BFormGroup,
        BButton,
        vueCountryRegionSelect,
    },
    data() {
        return {
            country: '',
            countryName: '',
            countryCode: '',
            tableColumns: [
                { key: 'countryName', label: 'Country Name' },
                { key: 'countryCode', label: 'Country Code' },
                { key: 'removecountry', label: 'Remove Country' },
            ],
        }
    },
    computed: {
        ...mapState({
            enabledCountryCodes: state => {
                return state.countriesSelection.countryCode
            },
            enabledCountries: state => {
                return state.countriesSelection.enabledCountries
            }
        })
    },
    methods: {
        ...mapActions({
            addCountry: 'countriesSelection/addCountry',
            getEnabledCountries: 'countriesSelection/getEnabledCountries',
            deleteCountry: 'countriesSelection/deleteCountry'
        }),
        async selectCountry(){
            await this.getEnabledCountries()
            if(!this.enabledCountryCodes.includes(this.countryCode)){
                if(this.countryName!='' && this.countryCode!=''){
                    await this.addCountry({countryName: this.countryName, countryCode: this.countryCode})
                }
            }
            await this.getEnabledCountries()
            this.countryCode = '',
            this.countryName = ''
        },
        async removeCountry(id){
            await this.deleteCountry(id)
            await this.getEnabledCountries()
        }
    },
    async mounted(){
        isoCountries.registerLocale(require("i18n-iso-countries/langs/en.json"));
        await this.getEnabledCountries()
    },
    watch:{
        countryCode:{
            handler(nVal, oVal){
                this.countryName = isoCountries.getName(this.countryCode, "en");
            }
        },
    }
}
</script>

<style>
.enabled_countries_root {
    margin-right: 4rem;
    padding: 2rem;
}

.countryselect {
    width: 90%;
}

.country_add {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}
</style>